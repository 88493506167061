import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';

import type { Community, User } from 'prayer-rotation-base';

import { LoadingComponent } from './shared/loading/loading.component';
import { LogoComponent } from './shared/logo/logo.component';
import type { AsyncState } from '../models/state';

@Component( {
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    RouterOutlet,
    LoadingComponent,
    LogoComponent,
  ],
} )
export class MainComponent {
  navigatingSignal = input.required< boolean >( { alias: 'navigating' } );
  currentUserStateSignal = input.required< AsyncState< User | undefined > >( { alias: 'currentUserState' } );
  communitySignal = input.required< Community | undefined >( { alias: 'community' } );
}
